import { render, staticRenderFns } from "./ExamTrainingDetail.vue?vue&type=template&id=458c0876&scoped=true"
import script from "./ExamTrainingDetail.vue?vue&type=script&lang=js"
export * from "./ExamTrainingDetail.vue?vue&type=script&lang=js"
import style0 from "./ExamTrainingDetail.vue?vue&type=style&index=0&id=458c0876&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "458c0876",
  null
  
)

export default component.exports